import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('@/Main.vue'),
    children: [

      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard')
      },
      {
        path: '/device',
        name: 'device',
        component: () => import('@/views/device')
      },
      {
        path: '/gateways',
        name: 'gateways',
        component: () => import('@/views/gateways')
      },
      {
        path: '/applications',
        name: 'applications',
        component: () => import('@/views/applications')
      },
      {
        path: '/dataProcessing',
        name: 'dataProcessing',
        component: () => import('@/views/dataProcessing')
      },
      {
        path: '/dataViewer',
        name: 'dataViewer',
        component: () => import('@/views/dataViewer')
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router