import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
Vue.prototype.$store = Vuex;
export default new Vuex.Store({
  state: {
    iframeUrl: "",
    pageRouter: "",
    token: "",
    userInfo: {},
    isIframe: false,
    theme: "",
    language: "",
  },
  mutations: {
    updateIframeUrl(state, iframeUrl) {
      state.iframeUrl = iframeUrl;
    },
    updatePageRouter(state, pageRouter) {
      state.pageRouter = pageRouter;
    },
    updateToken(state, token) {
      state.token = token;
    },
    updateIsIframe(state, isIframe) {
      state.isIframe = isIframe;
    },
    updateUserTheme(state, theme) {
      state.theme = theme;
    },
    updateLanguage(state, language) {
      state.language = language;
    },
    updateUserInfoKey(state, config) {
      let userInfo = state.userInfo;
      let key = config.key;
      let val = config.val;
      if (JSON.stringify(userInfo) != '{}') {
        userInfo[key] = val;
      }
    }
  },
  getters: {
    getIframeUrl(state) {
      return state.iframeUrl
    },
    getPageRouter(state) {
      return state.pageRouter
    },
    getToken(state) {
      return state.token
    },
    getAppIsIframe(state) {
      return state.isIframe
    },
    getUserInfo(state) {
      return state.userInfo
    },
    getUserInfoKey: (state) => (key) => {
      let userInfo = state.userInfo;
      if (JSON.stringify(userInfo) != '{}' && userInfo[key] != undefined) {
        return userInfo[key];
      } else {
        return ""
      }
    },
    getTheme(state) {
      return state.theme
    },
    getLanguage(state) {
      return state.language
    },
  },
  actions: {
    updateUserInfo(context, userInfo) {
      context.commit('updateUserInfo', userInfo);
    },
    updateUserInfoKey(context, config) {
      context.commit('updateUserInfoKey', config);
    }
  },
})