<template>
  <div :class="curThemeColor + '_color'">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      curThemeColor: "light",
    };
  },
  methods: {
    init() {
      let query = this.$route.query;
      let lang = "en",
        routeQuery = {},
        theme = "light";
      if (JSON.stringify(query) != "{}") {
        lang = query.language || "en";
        theme = query.theme || "light";
      }

      if (lang != this.$store.getters.getLanguage) {
        this.$store.commit("updateLanguage", lang);
        this.changeLanguage(lang);
        routeQuery.language = lang;
      }

      if (theme != this.$store.getters.getTheme) {
        routeQuery.theme = theme;
        this.$store.commit("updateUserTheme", theme);
      }
      if (routeQuery) this.checkRouterQuery(routeQuery);

      let token = sessionStorage.getItem("token");
      let userObj = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      if (
        userObj &&
        userObj.id &&
        this.$store.getters.getUserInfo != undefined &&
        userObj.id != this.$store.getters.getUserInfo.id
      ) {
        this.$store.dispatch("updateUserInfo", userObj);
      }

      if (
        token != this.$store.getters.getToken &&
        !this.$store.getters.getToken
      ) {
        this.$store.commit("updateToken", token);
        sessionStorage.setItem("token", token);
      }

      this.checkIframe();
      this.changeTheme(theme);
    },
    checkIframe() {
      let isIframe = false;
      if (self != top) {
        isIframe = true;
        // console.log("在iframe中");
      }
      if (isIframe != this.$store.state.isIframe)
        this.$store.commit("updateIsIframe", isIframe);
    },
    changeLanguage(lang) {
      switch (lang) {
        case "zh":
          this.$i18n.locale = "zh";
          break;
        case "tw":
          this.$i18n.locale = "tw";
          break;
        default:
          this.$i18n.locale = "en";
          break;
      }
    },
    checkRouterQuery(obj) {
      let query = this.$route.query;
      let num = 0;
      for (let k in obj) {
        if (query[k] && query[k] != obj[k]) {
          query[k] = obj[k];
          num++;
        }
      }

      if (num > 0) {
        let route = location.hash;
        route = route.substring(1);
        let tempArr = route.split("?");
        if (tempArr.length) {
          let path = tempArr[0];
          path = path.substring(1);
          query = this.checkUrlParams(tempArr[1]);
          this.$router.push({
            path: path,
            query: query,
          });
        } else {
          this.$router.push({
            path: this.$route.path,
            query: query,
          });
        }
      }
    },
    checkUrlParams(params) {
      params = params.replace(/&/g, '","').replace(/=/g, '":"');
      params = `{"${params}"}`;
      let query = JSON.parse(params);
      return query;
    },
    changeTheme(name) {
      this.curThemeColor = name;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    "$route.query.theme"(theme) {
      this.changeTheme(theme);
    },
  },
};
</script>

<style>
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>