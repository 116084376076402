export default {
  dark: 'Dark',
  detail: 'Detail',
  language: 'Language',
  theme: 'Theme',
  light: 'Light',
  purple: 'Purple',
  settings: 'Settings',

  home: 'Home',
  building: 'Building',
  iot: 'IoT',
  userManagement: 'User Management',
  roleManagement: 'Role Management',
  groupManagement: 'Group Management',
  listPage: 'List Page',
  dashboard: 'Dashboard',
  index: 'Index',
  add: 'Add',
  edit: 'Edit',
  reset: 'Reset',
  delete: ' Delete',
  save: 'Save',
  // login
  loginPlaceholder: "Log in using a user name or email",
  userName: "User Name",
  password: "Password",
  login: "Login",
  userLogin: "User Login",
  loginsSuccessful: "Login successfully",
  loginsFailed: "Login successfully",
  userNotExist: "User does not exist",
  userResetPassword: "Please reset the password first",
  wrongAccountOrPassword: "Wrong account or password",
  passwordIncorrect: "The password is incorrect",
  insufficientAuthority: "Insufficient authority",
  // edit password
  please: "Please",
  input: "input",
  retype: "retype",
  newPassword: "new password",
  notEmpty: "cannot be empty",
  editPassword: "Edit Password",
  twoPasswordNotSame: "The two passwords are inconsistent",
  modifiedSuccess: "Modified Success",
  modifiedFalied: "Modified Falied",
  oldPassword: "Old Password",
  error: "error",
  // user manage
  keyword: "Keyword",
  keywordPlaceholder: "username、nickname、email、phone、name",
  group: "Group",
  role: "Role",
  table: "Table",
  card: "Card",
  email: "Email",
  confirmPasswrod: "Confirm password",
  phone: "Phone",
  company: "Company",
  areYouSure: "Are You Sure ",
  plsEnterConfirmPass: "Please enter confirm password",
  plsEnterPhone: "Please enter confirm phone",
  incorrectPhoneFormat: "Incorrect phone format",
  incorrectEmailFormat: "Incorrect email format",
  itemNotEmpty: "The item cannot be empty",
  success: "success",
  successfully: " successfully",
  updated: " updated",
  added: " added",
  user: "User ",
  TheUserRoleSuccessfullyConfigured: "The user role is configured successfully!",
  // role manage
  description: "Description",
  roleGroup: "Role Group",
  // group manage
  APIQuotaUsedUp: "API call quota has been used up, please contact the administrator to upgrade",
  ParamsError: "Parameter error",
  Street: " Street",
  Country: "Country",
  Address: "Address",
  Birthday: "Birthday",
  Gender: " Gender",
  NickName: "Nick Name",
  name: "Name",
  male: "Male",
  female: "Female",
  unknown: "Unknown",
  username: "User Name",
  lastLogin: "Last Login Time",
  loginsCount: "Login Times",
  createdAt: "Creation Time",
  updatedAt: "Modification time",
  cancel: "Cancel",
  return: "Return",
  id: "ID",
  // 用户注册
  register: "Register",
  registeredSuccessfully: " Registered Successfully!",
  remberPassword: "Remember the password",
  forgetPassword: "Forget Password",
  resetPassword: "Reset password",
  returnLogin: "Return to login",
  resetPasswordInfo: "Reset password email sent to mailbox",
  valid5minutes: "valid for 5 minutes",
  code: "Verification Code",
  resendPasswordResetMail: "Resend password reset mail",
  passwordResetInfo: "Password reset",
  failed: "failed",
  tryAgainLater: "Try again later",
  mailSend: "Mail send",
  noticeRemind: "Message reminder",
  incorrectVerificationCode: "Incorrect verification code",
  addRegisterUser: "Add registered user",
  addRegisterUserSuccess: "Successfully added registered user",
  addRegisterUserFalid: "Failed to add registered user",
  applications: " applications ",
  contactAdministrator: " Please contact the administrator",
  // 20211116 XR
  detail: "Detail",
  enabled: "Enabled",
  status: "Status",
  actions: "Actions",
  emailRegistered: "Email has been registered",
  lastLoginTime: "Last Login Time",
  loginTimes: "Login Times",
  workPosition: "Work Position",
  industry: "Industry",
  province: "Province",
  city: "City",
  userInfo: "User info",
  delUserSuccessfully: "User deleted successfully",
  contactAddress: "Contact Address",
  copyEmail: "Copy email",
  emailCopySuccessfully: "Mail copy succeeded",
  userPictureSaveSuccessfully: "User avatar saved successfully",
  userChangePasswordSuccessfully: "User password modified successfully",
  userChangeNicknameSuccessfully: "User nickname modified successfully",
  passwordCannotBeEmpty: "Password cannot be empty",
  passwordCannotBeLess8: "The password cannot be less than 8 digits",
  passwordRules: "The password shall contain English upper and lower case letters and numbers",
  submit: "Submit",
  emailCode: "Email verification code",
  resendEmail: "Resend Email",
  mailSendSuccessfully: "Mail sent successfully",
  EmailVerificationSuccesfully: "Email verification succesfully",
  usernameRegistered: "User name already exists",
}
