export default {
  dark: '暗色',
  detail: '詳情',
  language: '語言',
  theme: '主題',
  light: '白色',
  purple: '紫色',
  settings: '設置',

  home: '主頁',
  building: '建築',
  iot: '傳感器',
  userManagement: '用戶管理',
  roleManagement: '角色管理',
  groupManagement: '分組管理',
  listPage: '列表頁面',
  dashboard: '儀表盤',
  index: '首頁',
  add: '添加',
  edit: '編輯',
  reset: '重置',
  delete: '刪除',
  save: '保存',
  // login
  loginPlaceholder: "您可以使用用戶名或者郵箱登錄",
  userName: "用戶名",
  password: "密碼",
  login: "登入",
  userLogin: "用戶登錄",
  loginsSuccessful: "登入成功",
  loginsFailed: "登入失敗",
  userNotExist: "用戶不存在",
  userResetPassword: "請先重置密碼",
  wrongAccountOrPassword: " 帳號或密碼錯",
  passwordIncorrect: "密碼不正確",
  insufficientAuthority: "許可權不足",
  // edit password
  please: "請",
  input: "輸入",
  retype: "重新輸入",
  newPassword: "新密碼",
  notEmpty: "不能為空",
  editPassword: "修改密碼",
  twoPasswordNotSame: "兩次輸入的密碼不一致",
  modifiedSuccess: "修改成功",
  modifiedFalied: "修改失敗",
  oldPassword: "原始密碼",
  error: "錯誤",
  // user manage
  keyword: "關鍵字",
  keywordPlaceholder: "用戶名、昵稱、郵箱、電話號碼、姓名",
  group: "分組",
  role: "角色",
  table: "表格",
  card: "卡片",
  email: "郵箱",
  confirmPasswrod: "確認密碼",
  phone: "電話",
  company: "公司",
  areYouSure: "你是否確認",
  plsEnterConfirmPass: "請輸入你的確認密碼",
  plsEnterPhone: "請輸入你的電話號碼",
  incorrectPhoneFormat: "電話格式不正確",
  incorrectEmailFormat: "郵箱格式不正确",
  itemNotEmpty: "該項目不能為空",
  success: "成功",
  successfully: "成功",
  updated: "修改",
  added: "創建",
  user: "用戶",
  TheUserRoleSuccessfullyConfigured: "成功配置用戶角色!",
  // role manage
  description: "描述",
  roleGroup: "許可權分組",
  // group manage
  APIQuotaUsedUp: "API調用額度已用完，請聯系管理員升級",
  ParamsError: "参数错误",
  Street: " 街道地址",
  Country: "國家",
  Address: "地址",
  Birthday: "生日",
  Gender: " 性别",
  NickName: "昵稱",
  name: "名字",
  male: "男",
  female: "女",
  unknown: "未知",
  username: "用戶名",
  lastLogin: "上次登錄時間",
  loginsCount: "登錄次數",
  createdAt: "創建時間",
  updatedAt: "修改時間",
  cancel: "取消",
  return: "返回",
  id: "ID",
  // 用户注册
  register: "注册",
  registeredSuccessfully: "注冊成功!",
  remberPassword: "記住密碼",
  forgetPassword: "忘記密碼",
  resetPassword: "重置密碼",
  returnLogin: "返回登入",
  resetPasswordInfo: "重置密碼郵件已發送至郵箱",
  valid5minutes: "有效期為5分鐘",
  code: "驗證碼",
  resendPasswordResetMail: "重新发送密码重置邮件",
  passwordResetInfo: "重置密碼",
  failed: "失敗",
  tryAgainLater: "稍後重試",
  mailSend: "郵件發送",
  noticeRemind: "消息提示",
  incorrectVerificationCode: "驗證碼不正確",
  addRegisterUser: "添加註冊用戶",
  addRegisterUserSuccess: "添加註冊用戶成功",
  addRegisterUserFalid: "添加註冊用戶失败",
  applications: "應用",
  contactAdministrator: "請聯系管理員",
  // 20211116 XR
  detail: "詳情",
  enabled: "啟用",
  status: "狀態",
  actions: "操作",
  emailRegistered: "郵箱已註冊",
  lastLoginTime: "最後登錄時間",
  loginTimes: "登錄次數",
  workPosition: "職位",
  industry: "行業",
  province: "省/州",
  city: "城市",
  userInfo: "用户信息",
  delUserSuccessfully: "用戶删除成功",
  contactAddress: "聯繫地址",
  copyEmail: "複製郵箱",
  emailCopySuccessfully: "郵箱複製成功",
  userPictureSaveSuccessfully: "用戶頭像保存成功",
  userChangePasswordSuccessfully: "用戶密碼修改成功",
  userChangeNicknameSuccessfully: "用戶昵稱修改成功",
  passwordCannotBeEmpty: "密碼不能為空",
  passwordCannotBeLess8: "密碼不能少於8位",
  passwordRules: "密碼應包含英文大寫和小寫字母和數位",
  submit: "提交",
  emailCode: "電郵驗證碼",
  resendEmail: "重新發送電子郵件",
  mailSendSuccessfully: "郵件已成功發送",
  EmailVerificationSuccesfully: "電子郵件驗證成功",
  usernameRegistered: "用戶名已存在",
}
