export default {
  dark: '暗色',
  detail: '详情',
  language: '语言',
  theme: '主题',
  light: '白色',
  purple: '紫色',
  settings: '设置',

  home: '主页',
  building: '建筑',
  iot: '传感器',
  userManagement: '用户管理',
  roleManagement: '角色管理',
  groupManagement: '分组管理',
  listPage: '列表页面',
  dashboard: '仪表盘',
  index: '首页',
  add: '添加',
  edit: '编辑',
  reset: '重置',
  delete: '删除',
  save: '保存',
  // login
  loginPlaceholder: "您可以使用用户名或者邮箱登录",
  userName: "用户名",
  password: "密码",
  login: "登录",
  userLogin: "用户登录",
  loginsSuccessful: "登录成功",
  loginsFailed: "登录失败",
  userNotExist: "用户不存在",
  wrongAccountOrPassword: " 帐号或密碼错",
  userResetPassword: "请用户重置密码",
  passwordIncorrect: "密码不正确",
  insufficientAuthority: "权限不足",
  // edit password
  please: "请",
  input: "输入",
  retype: "重新输入",
  newPassword: "新密码",
  notEmpty: "不能为空",
  editPassword: "修改密码",
  twoPasswordNotSame: "两次输入的密码不一致",
  modifiedSuccess: "修改成功",
  modifiedFalied: "修改失败",
  oldPassword: "原始密码",
  error: "错误",
  // user manage
  keyword: "关键字",
  keywordPlaceholder: "用户名、昵称、邮箱、电话号码、姓名",
  group: "分组",
  role: "角色",
  table: "表格",
  card: "卡片",
  email: "邮箱",
  confirmPasswrod: "确认密码",
  phone: "电话",
  company: "公司",
  areYouSure: "你是否确认",
  plsEnterConfirmPass: "请输入你的确认密码",
  plsEnterPhone: "请输入你的电话号码",
  incorrectPhoneFormat: "电话格式不正确",
  incorrectEmailFormat: "邮箱格式不正确",
  itemNotEmpty: "该项目不能为空",
  success: "成功",
  successfully: "成功",
  updated: "修改",
  added: "創建",
  user: "用户",
  TheUserRoleSuccessfullyConfigured: "成功配置用户角色!",
  // role manage
  description: "描述",
  roleGroup: "权限分组",
  // group manage
  APIQuotaUsedUp: "API 调用额度已用完，请联系管理员升级",
  ParamsError: "参数错误",
  Street: " 街道地址",
  Country: "国家",
  Address: "地址",
  Birthday: "生日",
  Gender: " 性别",
  NickName: "昵称",
  name: "名字",
  male: "男",
  female: "女",
  unknown: "未知",
  username: "用户名",
  lastLogin: "上次登录时间",
  loginsCount: "登录次数",
  createdAt: "创建时间",
  updatedAt: "修改时间",
  cancel: "取消",
  return: "返回",
  id: "ID",
  // 用户注册
  register: "注册",
  registeredSuccessfully: "注册成功!",
  remberPassword: "记住密码",
  forgetPassword: "忘记密码",
  resetPassword: "重置密码",
  returnLogin: "返回登录",
  resetPasswordInfo: "重置密码邮件已发送至邮箱",
  valid5minutes: "有效期为 5 分钟",
  code: "验证码",
  resendPasswordResetMail: "重新发送密码重置邮件",
  passwordResetInfo: "重置密码",
  failed: "失败",
  tryAgainLater: "稍后重试",
  mailSend: "邮件发送",
  noticeRemind: "消息提示",
  incorrectVerificationCode: "验证码不正确",
  addRegisterUser: "添加注冊用戶",
  addRegisterUserSuccess: "添加注冊用戶成功",
  addRegisterUserFalid: "添加注冊用戶失败",
  applications: "应用",
  contactAdministrator: "请联系管理员",
  // 20211116 XR
  detail: "详情",
  enabled: "启用",
  status: "状态",
  actions: "操作",
  emailRegistered: "邮箱已注册",
  lastLoginTime: "最后登录时间",
  loginTimes: "登录次数",
  workPosition: "职位",
  industry: "行业",
  province: "省/州",
  city: "城市",
  userInfo: "用户信息",
  delUserSuccessfully: "用户删除成功",
  contactAddress: "联系地址",
  copyEmail: "复制邮箱",
  emailCopySuccessfully: "邮箱复制成功",
  userPictureSaveSuccessfully: "用户头像保存成功",
  userChangePasswordSuccessfully: "用户密码修改成功",
  userChangeNicknameSuccessfully: "用户昵称修改成功",
  passwordCannotBeEmpty: "密码不能为空",
  passwordCannotBeLess8: "密码不能少于8位",
  passwordRules: "密码应包含英文大写和小写字母和数字",
  submit: "提交",
  emailCode: "邮件验证码",
  resendEmail: "重新发送邮件",
  mailSendSuccessfully: "邮件已发送成功",
  EmailVerificationSuccesfully: "电子邮件验证成功",
  usernameRegistered: "用户名已存在",
}
