global.ENCODE = (data) => {
  let str = data.reduce(function (a, b) {
    return a + String.fromCharCode(b);
  }, "");
  return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
}
global.DIFF_ARRS = (arrA, arrB, key) => {
  if (key) {
    return arrA.filter(
      (a) => !arrB.some((b) => b[key] == a[key])
    )
  } else {
    return arrA.filter(
      (a) => !arrB.some((b) => b == a)
    )
  }
}
global.FORMATDATE = (dateStr, state) => {
  if (
    !dateStr ||
    new Date(dateStr).getTime() < new Date("1970-01-01").getTime()
  ) {
    return "";
  }
  let d = new Date(dateStr);

  if (!isNaN(d)) {
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    if (state) {
      return (
        year.toString() +
        "-" +
        autoAddZero(month + 1) +
        "-" +
        autoAddZero(day) +
        " " +
        autoAddZero(h) +
        ":" +
        autoAddZero(m) +
        ":" +
        autoAddZero(s)
      );
    } else {
      return (
        year.toString() +
        "-" +
        autoAddZero(month + 1) +
        "-" +
        autoAddZero(day)
      );
    }

  }
};
function autoAddZero(num) {
  let digit = FOMATNUMBER(num);
  if (digit >= 0 && digit < 10) {
    return "0" + digit;
  }
  return digit;
}
function FOMATNUMBER(num) {
  let numb = Number(num);
  if (isNaN(numb)) {
    return 0;
  } else {
    return numb;
  }
}
var s3FileType = 5;
global.CREAGEIMGKEY = (Id) => {
  let userId = Id;
  let d = new Date();
  let year = d.getFullYear();
  let month = autoAddZero(d.getMonth() + 1);
  let day = autoAddZero(d.getDate());
  let h = autoAddZero(d.getHours());
  let m = autoAddZero(d.getMinutes());
  let s = autoAddZero(d.getSeconds());
  let random = (Math.random() * 10000).toFixed(0);
  if (random.length === 3) {
    random = random + '0'
  }
  let imgNameKey = '' + year + month + day + '_' + h + m + s + '_' + random + '_' + s3FileType;
  return userId + "/" + imgNameKey;
}

