import axios from "axios";
// 影响请求天气数据
// axios.defaults.withCredentials = true;
// 添加请求拦截器
axios.defaults.timeout = 30000;
axios.interceptors.request.use(
  config => {
    // config.withCredentials = true;
    const token = sessionStorage.getItem("token");

    let name = config.url;
    // && !name.includes('18.162.67.140')
    if (token && !name.includes('weather') && !name.includes("api.waqi.info")) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么

    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  res => {
    if (
      res.status === 200 &&
      res.data.error === "Your Session was expired or you need login first!" &&
      myVue.$route.path !== "/"
    ) {
      myVue.$Message.error({
        content: "请先登录再进行操作",
        duration: 10
      });
      myVue.$router.push("/");
    } else if (res.status == 200) {
      return res.data
    } else {
      return res;
    }
  },
  error => {
    // console.log(JSON.stringify(error))
    let err = JSON.stringify(error)
    let obj = JSON.parse(err)
    // console.log(myVue)
    // console.log(typeof(obj))
    if (obj.message.includes(401)) {

      myVue.$Message.destroy();
      myVue.$Message.error({
        content: "Please login first",
        duration: 10
      });
      myVue.$router.push("/");
      return obj;
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default axios;
