import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/tools/tools.js'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import VueI18n from 'vue-i18n'
import en from 'view-design/dist/locale/en-US'
import zh_cn from 'view-design/dist/locale/zh-CN'
import zh_tw from 'view-design/dist/locale/zh-TW'
import localhostEn from '../public/language/en'
import localhostZhCn from '../public/language/zh.js'
import localhostZhTw from '../public/language/tw.js'
import Axios from './tools/axios';
// 全局样式
import './style/public.less'

Vue.use(VueI18n);
Vue.use(ViewUI);

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.locale = () => { };
const messages = {
  en: Object.assign(localhostEn, en),
  zh: Object.assign(localhostZhCn, zh_cn),
  tw: Object.assign(localhostZhTw, zh_tw),
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: '',  // set locale
  messages,  // set locale messages
  silentTranslationWarn: true
});

window.myVue = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
